import { useState, useEffect, useContext} from "react";
import { FlashContext } from '../flash-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
import { Payment } from '../models/payment';
import axios from "axios";


export default function PaymentForm(props) {
  const [builtAttrs, setBuiltAttrs] = useState([])
  const [fundCode, setFundCode] = useState(null)
  const { flash, setFlash } = useContext(FlashContext);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const [showPayWithCredit, setShowPayWithCredit] = useState(false);

  useEffect(() => {
    //console.log('i fire once');
    getFormAttrs();
  }, [])

  const buildFormAttrs = (theAttrs) => {
    console.log("ATTRS", theAttrs)
    for (const key in theAttrs){
      setBuiltAttrs(current => [...current,  <input type="hidden" key={key} name={key} value={theAttrs[key]}/>])
    }
  }

  const getFormAttrs = () => {
    const options = {method: 'GET',url: `payments/${props.jobId}/attrs`};
    axios.request(options).then(function (response) {
      buildFormAttrs(response.data);
    }).catch(function (error) {
      console.error(error);
      setFlash({type: "danger",  message: "Unable to process credit card payment."})
    });
  }

  const fundCodePayment = () => {
    return <div className="row">
      <div className="col-sm">
        <input onChange={(e) => validateAndUpdate(e)} placeholder="Fund Code (xxx-xxxx)" type="text" className="form-control" id="fund-code" aria-describedby="fundCodeHelpLine"/>
        {validationMessage}
      </div>
      <div className="col-sm">
        <button onClick={(e) => payWithFundCodeNew(e)} type="submit" data-cy="pay-with-fundcode-button" className="btn btn-primary btn-sm mb-0" disabled={!allowSubmit}>
          <FontAwesomeIcon icon={faMoneyCheckDollar} /> Pay With Fund Code
        </button>
        <button className="btn btn-sm mb-1 btn-outline-secondary" onClick={(e) => {setShowPayWithCredit(!showPayWithCredit)}} >No fund code?</button>
        {payWithCredit()}
      </div>
    </div>
  }

  const validateAndUpdate = (e) => {
    const inputValue = e.target.value;
    setFundCode(inputValue);
    if (inputValue.length >= 7) {
      Payment.getFundCodeData(inputValue).then(response => {
        const details = response.data.data;
        if (details.valid) {
          setValidationMessage(<span className="badge text-bg-success">
            {details.description}: {details.display}
          </span>);
          setAllowSubmit(true);
        } else if (!details.valid && details.valid_to) {
          const today = new Date();
          const expiration = new Date(details.valid_to);
          if (expiration < today) {
            setValidationMessage(<span className="badge text-bg-danger">
              Fund code expired on {expiration.toDateString()}
            </span>);
          } 
        } else {
          setValidationMessage(<span className="badge text-bg-danger">
            Please use a valid fund code
          </span>);
          setAllowSubmit(false);
        }
      }).catch(err => {
        console.log(err);
        setValidationMessage(<span className="badge text-bg-danger">
          Unable to reach fund code validation service
        </span>);
        setAllowSubmit(false);
      })
    } else {
      if (!allowSubmit) setAllowSubmit(false);
      if (validationMessage) setValidationMessage(null);
    }
  }

  const payWithFundCodeNew = (e) => {
    e.preventDefault();
    if (allowSubmit) {
      setAllowSubmit(false);
      Payment.fundCodePayment(props.jobId, fundCode).then(response => {
        setFlash({ type: "success", message: "Fund code payment successful!" });
        props.refreshJob();
      }).catch(err => {
        console.log(err);
        setFlash({ type: "danger", message: "Unable to reach fund code validation service" });
        setAllowSubmit(true);
      })
    } else {
      setFlash({ type: "danger", message: "Please use a valid fund code" });
    }
    return 0;
  }

  const payWithCredit = () => {
    return showPayWithCredit ? (<div>
      <form id="payment_confirmation" action={process.env.REACT_APP_CYBERSOURCE_URL} method="post">
        {builtAttrs}
        <h6>If you have access to a Duke fund code, please use it. Duke Purchasing Cards should not be used to pay for Bluesmith jobs.</h6>
        <button type="submit" className="btn btn-link float-end btn-sm text-nowrap" data-cy="pay-with-credit-button">If you have a personal credit card, click here</button>
      </form>
      </div>) : ('')
  }

  return <>
    {fundCodePayment()}
  </>
}