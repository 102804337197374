import React from 'react';
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv";



export default function BsDataTable(props) {

  const filterTableData = (e) => {
    //this beast searches the substrings of all the values in the row of payments to return a match
    props.setFilteredData(props.originalData.filter(obj => Object.values(obj).find(elm => searchSubString(elm, e.target.value))))
  }

  const searchSubString = (elm, value) => {
    if (elm?.className === "badge text-bg-primary") {
      console.log("element: ", elm)
    }

    // console.log("value: ", value)
    if (elm) { return elm.toString().toLowerCase().includes(value.toLowerCase()) }
  }

  const tableFilter = () => {
    const handleKeyDown = (e) => {
      // Check if the user pressed the "Enter" key AND if the input is empty
      if (e.key === "Enter" && e.target.value.trim() === "") {
        props.setFilteredData(props.originalData);  // Call reset function if the input is empty and Enter is pressed
        if (props.onReset) props.onReset();
      }
    }
  
    return <>
      <div className="row g-3">
        <div className="col-auto">
          <input 
            className="form-control" 
            data-cy="search-user" 
            placeholder='search' 
            onChange={filterTableData}  // This triggers filtering when the user types
            onKeyDown={handleKeyDown}   // This triggers reset when Enter is pressed and input is empty
            type='text' 
          /><br />
        </div>
      </div>
    </>
  }
  

  const showTable = () => {

    if (props.filteredData.length > 0) {
      const columns = []

      //set the column headers for the data table
      for (const key in props.columnSettings) {
        columns.push(
          {
            name: key,
            selector: props.columnSettings[key],
            sortable: true
          }
        )
      }

      return <DataTable
        columns={columns}
        data={props.filteredData}
        {...console.log("props: ", props.filteredData)}
        sortFunction={customSort}
        pagination //enables pagination
      />
    }
  }

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      let aField = rowA[selector]
      let bField = rowB[selector]
      // sort by created_at time but display pretty_created_at time
      if (selector === "pretty_created_at") {
        aField = rowA["created_at"];
        bField = rowB["created_at"];
      } else if (selector === "assigned_to") {
        aField = rowA["staffers"][0]?.display_name;
        bField = rowB["staffers"][0]?.display_name;
        // console.log("afield: ", aField);
        // console.log("bfield: ", bField);
      } else if (selector === "pretty_paid_at") {
        aField = rowA["paid_at"];
        bField = rowB["paid_at"];
      }
      let comparison = 0;
      if ((aField > bField) || !bField ) {
        comparison = 1;
      } else if ((aField < bField) || !aField) {
        comparison = -1;
      } else {
        console.log("WELL F")
      }

      return direction === 'desc' ? comparison * -1 : comparison;
    });
  };

  return <>
    <div>
      <CSVLink className="link-info" filename={props.csvFilename} data={props.filteredData}>Download CSV</CSVLink>
    </div>
    {tableFilter()}
    <div data-cy="bs-data-table">
      {showTable()}
    </div>

  </>
}