import { useContext, useEffect, useState, useRef } from "react";
import Table from "./Table";
import axios from "axios";
import { handleInputChange, makeNumberCurrency } from "../helpers";
import { FlashContext } from "../flash-context";
import { Button } from "../common/Button";

export default function CostTable(props) {
    const [itemId, setItemId] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [itemDescId, setItemDescId] = useState(false);
    const [itemDescName, setItemDescName] = useState(false);
    const [itemDescMachineId, setItemDescMachineId] = useState(false);
    const [itemDescMaterialId, setItemDescMaterialId] = useState(false);
    const [itemDescIndex, setItemDescIndex] = useState(false);
    const [materialCostsDict, setMaterialCostsDict] = useState({});
    const [itemTypeId, setItemTypeId] = useState(props.typeId);
    const [itemNotes, setItemNotes] = useState(false);
    const [showItems, setShowItems] = useState([]);
    const [itemDescs, setItemDescs] = useState([]);
    const { flash, setFlash } = useContext(FlashContext);

    useEffect(() => {
        let descs = [];
        let costs = {};

        props.machineMaterialCombos.map((m, index) => {
            if (m.machine_id != 0 && m.material_id != 0) {
                let name =`${m.machine_name} | ${m.material_name}` 
                descs.push({'desc_id': false, 'name': name, 'machine_id': m.machine_id, 'material_id': m.material_id});
                costs[name] = m.material_cost
            }
        })

        props.machines.forEach((m) => {
            descs.push({"desc_id": false, "name": "Printer Base Charge", "machine_id": -1, "material_id": -1 });
            costs["Printer Base Charge"] = m.base_charge
            descs.push({"desc_id": false, "name": "Hourly Charge", "machine_id": -1, "material_id": -1 });
            costs["Hourly Charge"] = m.hourly_cost
        })

        axios.get('item_descs/default').then(function (response) {
            response.data.map((d, index) => {
                descs.push({'desc_id': d.id, 'name': d.name, 'machine_id': -1, 'material_id': -1});
            })
        }).catch(function (error) {
            console.error(error);
        });

        console.log(descs)
        console.log(costs)
        setItemDescs(descs);
        setMaterialCostsDict(costs);
    }, [props.machineMaterialCombos])

    useEffect(() => {
        if (props.reset){
            console.log("reset")
            resetItem();
        }
        console.log("waiting")
    }, [props.reset])

    useEffect(() => {
        displayItems(props.items);
    }, [props.items, itemId, subtotal, itemNotes, itemDescId, itemDescName, itemDescMachineId, itemDescMaterialId])

    useEffect(() => {
        setSubtotal(Math.round(quantity * price * 100) / 100);
    }, [quantity, price])

    const resetItem = () => {
        props.getJob();
        setQuantity(0);
        setPrice(0);
        setItemDescId(false);
        setItemDescName(false);
        setItemDescMachineId(false);
        setItemDescMaterialId(false);
        setSubtotal(0);
        setItemNotes(false);
        setItemId(false);
    }

    const setEditItem = (index, idValue) => {
        let item = props.items[index];
        setQuantity(item.quantity);
        setPrice(item.price);
        setItemDescIndex(itemDescs.findIndex(i => i.name === item.item_desc));
        //console.log("whats price:", item.price);
        setItemDescId(item.item_desc_id);
        setSubtotal(item.subtotal);
        setItemNotes(item.notes);
        setItemId(idValue);
        props.setReset(false);
    }

    const handleDescChange = (e) => {
        if (e.target.value) {
            let vals = itemDescs[e.target.value];
            setItemDescId(vals.desc_id);
            setItemDescName(vals.name);
            setItemDescMachineId(vals.machine_id);
            setItemDescMaterialId(vals.material_id);
            setPrice(materialCostsDict[vals.name]);
        } else {
            setItemDescId(false);
            setItemDescName(false);
            setItemDescMachineId(false);
            setItemDescMaterialId(false);
            setPrice(0);
        }
    }

    const showItemDescs = () => {
        const options = [<option value={false} key={-1} hidden>Select</option>, ...itemDescs.map((desc, index) => <option value={index} key={index}>{desc.name}</option>)];
        return <div className="col">
            <select onChange={e => handleDescChange(e)} className="form-select" aria-label="line item" id="line item" defaultValue={itemDescIndex}>
                {options}
                {/* {itemDescs.map((p, index) => <option value={p.id} key={p.name + index}>{p.name}</option>)} */}
            </select>
        </div>
    }

    const updateItemValue = async(idValue, index) => {
        if (idValue === "cancel") {
            resetItem();
        } else if (!itemId) {
            console.log("EDIT", index, idValue)
            setEditItem(index, idValue);
        } else if (itemId !== idValue) {
            alert("Make sure you save the previous edit!");
        } else {
            let item = {
                id: idValue,
                quantity: quantity,
                price: price,
                subtotal: subtotal,
                itemDescId: itemDescId,
                itemDescName: itemDescName,
                itemDescMachineId: itemDescMachineId,
                itemDescMaterialId: itemDescMaterialId,
                itemNotes: itemNotes,
                itemTypeId: itemTypeId
            }
            // reset doesn't work yet
            props.updateItem(item);
        }
    }

    const deleteItem = (idValue) => {
        if (window.confirm("Are you sure you want to delete this item?")) {

            const options = {
                method: "DELETE",
                url: `line_items/${idValue}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                }
            };

            axios.request(options).then(function (response) {
                if (response.data.error) {
                    console.log(response.data);
                } else {
                    resetItem();
                    setFlash({ type: "success", message: "Deleted Item!" });
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    }

    const buttons = (l, index) => {
        let editOrSave = itemId === l.id ?
            <Button className="btn btn-primary" label="Save" dataCy="admin-cost-save-button" onClick={() => updateItemValue(l.id, index)} /> :
            <Button className="btn btn-primary" label="Edit" dataCy="admin-cost-edit-button" onClick={() => updateItemValue(l.id, index)} />;
        let cancel = itemId === l.id ?
            <Button className="btn btn-primary" label="Cancel" onClick={() => updateItemValue("cancel")} /> : '';
        let deleteBtn = itemId !== "-1" ?
            <Button className="btn btn-primary" label="Delete" dataCy="admin-cost-delete-button" onClick={() => deleteItem(l.id)} /> : '';
        return <div>
            {editOrSave}
            {cancel}
            {deleteBtn}
        </div>
    }

    const eachItem = (l, index) => {
        if (itemId === l.id) {
            return {
                "description": showItemDescs(),
                "notes": <input onChange={e => handleInputChange(e, setItemNotes)} type="text" className="form-control" id="itemNotes" aria-describedby="itemNotes" defaultValue={itemNotes || ''} />,
                "quantity": <input onChange={e => handleInputChange(e, setQuantity)} type="number" className="form-control" id="quantity" aria-describedby="quantity" required min={1} defaultValue={quantity || 1} />,
                // the key in price is so that the element re-renders when price is updated
                "price": <input onChange={e => handleInputChange(e, setPrice)} type="number" className="form-control" id="price" aria-describedby="price" key="price-input" required min={0} value={price} />,
                "subtotal": <input type="number" className="form-control" id="subtotal" aria-describedby="subtotal" required min={0} value={subtotal || 0} disabled readOnly />,
                "action": buttons(l, index)
            }
        } else {
            //console.log("what is l: ", l);
            return {
                "description": <span>{l.item_desc || "None"}</span>,
                "notes": <span>{l.notes || ''}</span>,
                "quantity": <span>{l.quantity || 0}</span>,
                "price": <span>${makeNumberCurrency(l.price) || 0}</span>,
                "subtotal": <span>${makeNumberCurrency(l.subtotal) || 0}</span>,
                "action": buttons(l, index)
            }
        }
    }

    const displayItems = (newItems) => {
        console.log(newItems);
        let temp = [];
        newItems.map((l, index) => {
            temp.push(eachItem(l, index));
        })
        setShowItems(temp);
    }

    const addItem = () => {
        let newItems = props.items;
        newItems.push({ id: "-1" });
        setItemId("-1");
//        props.setItems(newItems);
        props.setReset(false);
        displayItems(newItems);
    }


    const addItemBtn = itemId !== "-1" ?
        <Button className="btn btn-primary btn-margin-fix admin-addon-btn" dataCy="admin-cost-add-item" type="button" id="line_items" label="Add Item" onClick={addItem} /> : '';

    return <>
        <Table colName={["Description", "Notes", "Quantity", "Price", "Subtotal", "Action"]} data={showItems} />
        {addItemBtn}
    </>
}