import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './user/HomePage';
import JobsPage from './user/JobsPage';
import Navbar from './user/Navbar';
import ServicePage from './user/ServiceOptions';
import CreateJob from './user/CreateJob';
import ViewJob from './user/ViewJob';
import { useState, useEffect } from 'react';
import AdminNavbar from './admin/AdminNavbar';
import MaterialPage from './admin/materials/MaterialPage';
import AdminJobsPage from './admin/JobsPage';
import UsersPage from './admin/UsersPage';
import axios from 'axios';
import Capabilities from './user/Capabilities';

import { UserContext } from "./user-context";
import AdminViewJob from './admin/AdminViewJob';
import PrinterPage from './admin/printers/PrinterPage';
import ViewMaterial from './admin/materials/ViewMaterial';
import ViewPrinter from './admin/printers/ViewPrinter';
import { FlashContext } from './flash-context';
import { axiosGlobalIntercept } from './axiosGlobalIntercept';
import CreatePrinter from './admin/printers/CreatePrinter';
import CreateMaterial from './admin/materials/CreateMaterial';
import PaymentHistory from './admin/PaymentHistory';
import ChatView from './common/chat/ChatView';
import ContentPage from './admin/contents/ContentPage';
import CreateContent from './admin/contents/CreateContent';
import ViewContent from './admin/contents/ViewContent';
import MaterialAdditionalFields from './admin/materials/MaterialAdditionalFields';
import NotFoundPage from './NotFoundPage';




function App() {
  const [user, setUser] = useState(null);
  const value = { user, setUser };

  const [flash, setFlash] = useState(null);
  const flashValue = { flash, setFlash };

  useEffect(() => {
    axiosGlobalIntercept(setFlash);
    //set userContext to user user object throughout app
    let url = process.env.REACT_APP_API_HOST + '/saml/current_user';
    axios.get(url).then(function (response) {
      if (response.status === 204) {
        setUser(false);
      } else {
        localStorage.setItem('isLoggedIn', true);
        setUser({
          netid: response.data.netid,
          duid: response.data.duid,
          id: response.data.id,
          displayName: response.data.display_name,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          actAs: response.data.act_as,
          type: response.data.type
        })

        if (localStorage.getItem('redirect_url')) {
          const redirectUrl = localStorage.getItem('redirect_url') 
          localStorage.removeItem('redirect_url'); 
          window.location.href = redirectUrl
      }
      }
    });
  }, []); //note the [] here is preventing an infinite loop

  const actAsAlert = () => {
    //if the user is not null AND is acting as, then success 
    if (user && user.actAs) {
      const flash = {
        type: "success",
        message: `Acting As ${user.displayName}!`,
        showEndButton: true
      };
  
      // Render the alert with the flash message details
      return (
        <div id="flash-message" className={`alert alert-${flash.type}`}>
          {flash.message}
          {flash.showEndButton && (
            <button
              id="endActAs"
              onClick={endActAs}
              type="button"
              className="btn"
            >
              End Act As
            </button>
          )}
        </div>
      );
    }
    return null;
  };
  
  const endActAs = () => {

    const options = {
      method: "GET",
      url: `saml/act_as_logout`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'enctype': 'multipart/form-data'
      }
    };

    axios.request(options).then(function (response) {
      if (response.data.error) {
        console.log(response.data);
      } else {
        window.location.reload();
        console.log("Act as session ended successfully.")
      }
    }).catch(function (error) {
      console.error(error);
    });
  }




  const showJob = () => {
    return <>
      <Route path="jobs" element={<JobsPage />}></Route>
      <Route path="jobs/:id" element={<ViewJob />}></Route>
      <Route path="createJob" element={<CreateJob />}></Route>
      <Route path="payments/creditcard_cancel" element={<JobsPage />}></Route>
      <Route path="payments/creditcard_return" element={<JobsPage />}></Route>
    </>
  }

  const showAdmin = () => {
    // admin routes
    return <Route path="admin" element={<AdminNavbar />}>
      <Route index element={<AdminJobsPage />}></Route>
      <Route path=":id" element={<AdminViewJob />}></Route>
      <Route path="completed" element={<AdminJobsPage />}></Route>
      <Route path="pending_staff" element={<AdminJobsPage />}></Route>
      <Route path="pending_client" element={<AdminJobsPage />}></Route>

      <Route path="all" element={<AdminJobsPage />}></Route>
      <Route path="all/:id" element={<AdminViewJob />}></Route>
      <Route path="materials/additional_fields" element={<MaterialAdditionalFields />}></Route>
      <Route path="materials" element={<MaterialPage />}></Route>
      <Route path="materials/:id" element={<ViewMaterial />}></Route>
      <Route path="materials/createMaterial" element={<CreateMaterial />}></Route>
      <Route path="payment_history" element={<PaymentHistory />}></Route>
      <Route path="content_editor" element={<ContentPage />}></Route>
      <Route path="content_editor/createContent" element={<CreateContent />}></Route>
      <Route path="content_editor/:id" element={<ViewContent />}></Route>
      <Route path="printers" element={<PrinterPage />}></Route>
      <Route path="printers/createPrinter" element={<CreatePrinter />}></Route>
      <Route path="printers/:id" element={<ViewPrinter />}></Route>
      <Route path="users" element={<UsersPage />}></Route>
    </Route>
  }



  return (
    <FlashContext.Provider value={flashValue}>

      <UserContext.Provider value={value}>
        <BrowserRouter>
        {actAsAlert()}
          <Routes>
            <Route path="/" element={<Navbar />}>
              <Route index element={<HomePage />}></Route>
              <Route path="services" element={<ServicePage />}></Route>
              {/* adding a route to capabilities page */}
              <Route path="capabilities" element={<Capabilities />}></Route>
              {user ? showJob():''}
              {/* temp */}
              <Route path="chat" element={<ChatView />}></Route>
            </Route>
            {user && (user.type === "Admin" || user.actAs) ? showAdmin() : ''}
            {user && (user.type === "Employee" || user.actAs) ? showAdmin() : ''}
            <Route path='*' element={<NotFoundPage />}/>
          </Routes >
        </BrowserRouter>
      </UserContext.Provider>
    </FlashContext.Provider>

  );
}

export default App;
