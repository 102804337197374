import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FlashContext } from "../flash-context";
import { UserContext } from "../user-context";
import BsDataTable from "../common/BsDataTable";
import { User } from "../models/user";
import { handleInputChange } from "../helpers";
import './admin.css';

export default function UsersPage() {
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])

  const [userUniqueID, setUserUniqueID] = useState("");
  const roles = ["Admin", "BsClient", "HighRoller", "Employee"];
  const { user, setUser } = useContext(UserContext);
  const { flash, setFlash } = useContext(FlashContext); 

  const userRoles = (user) => {
    return <div className="col" data-cy="user-role">
      {user.type}
    </div>
  }

  const getUsers = () => {
    User.all().then((response) => {
      if (response.response) {
          console.log(response);
      } else {
        let formatUser = response.data.map(u => {
          return {
            id: u.id,
            display_name: u.display_name,
            email: u.email,
            duid: u.duid,
            type: userRoles(u), 
            actAs: <button className="btn btn-primary" type="button" id="actAs" onClick={() => actAs(u.duid)}>Act As</button>
          }
        })
        console.log(formatUser);
        setUsers(formatUser);
        setFilteredUsers(formatUser);
      }
    });
  }

  useEffect(() => {
    getUsers();
  }, [])

  const actAs = (duid) => {
    const options = {
      method: "GET",
      url: `saml/act_as?duid=${duid}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'enctype': 'multipart/form-data'
      }
    };

    axios.request(options).then(function (response) {
      if (response.data.error) {
        console.log(response.data);
      } else {
        
        getUsers();
        window.location.href = "/admin/users";
      }
    }).catch(function (error) {
      console.error(error);
    });
  }


  const addUser = (uniqueID) => {
    document.getElementById("UserAdd").value = "";
    User.create({duid: uniqueID}).then(function (response) {
        console.log(response.data);
        if (response.data.error) {
          setFlash({ type: "danger", message: response.data.message });
        } else {
          getUsers();
          setFlash({ type: "success", message: "User added!" });
        }
    })
  }

  const grouperSync = () => {
    setFlash({ type: "info", message: "Syncing user roles with grouper. Please wait..." });
    User.grouperSync().then(function (response) {
        console.log(response.data);
        if (response.data.error) {
          setFlash({ type: "danger", message: response.data.message });
        } else {
          setFlash({ type: "success", message: "User roles updated from grouper." });
          getUsers()
        }
    })
  }

  const showTable = () => {
    // the key is the display you want on the table and the value is the key for the data returned from the api
    let columnSettings;
    if(user.type === "Admin"){
      columnSettings = {
        '#': 'id',
        'Name': 'display_name',
        'Email': 'email',
        'DUID': 'duid',
        'Role': 'type',
        'ActAs': 'actAs'
      }
    }else{
      columnSettings = {
        '#': 'id',
        'Name': 'display_name',
        'Email': 'email',
        'DUID': 'duid',
        'Role': 'type'
      }
    }


  
   
    return <BsDataTable
      setFilteredData={setFilteredUsers} 
      columnSettings={columnSettings} 
      filteredData={filteredUsers} 
      originalData={users}
      csvFilename="bluesmith-users.csv"
      dataCy="bs-data-table"
    />
  }


  return <>
    <h1>Users Page</h1>
    <div className='rowC'>
      <input onChange={e => handleInputChange(e, setUserUniqueID)} type="text" className="form-control" id="UserAdd" aria-describedby="UserAdd" 
              placeholder="Input user's unique ID" required defaultValue="" 
              style = {{width: "200px", marginRight: "5px"}} />
      <button className="btn btn-outline-secondary" type="button" id="addUser" onClick={() => addUser(userUniqueID)}>Add User</button> 
      <button className="btn btn-outline-secondary row-btn" type="button" id="grouperSync" onClick={() => grouperSync()}>Grouper Sync</button>
    </div>
    {showTable()}
  </>
}