
import React from 'react';
import { useContext, useEffect, useState} from "react";
import axios from "axios";
import { FlashContext } from '../flash-context';
import DataTable from 'react-data-table-component';
import { CSVLink, CSVDownload } from "react-csv";
import BsDataTable from '../common/BsDataTable';
import { Payment } from '../models/payment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function PaymentsHistory(){
  const { flash, setFlash } = useContext(FlashContext);
  const [paymentHistory, setPaymentHistory] = useState([])
  const [filteredHistory, setFilteredHistory] = useState([])
  const [filterStartDate, setFilterStartDate] = useState(null)
  const [filterEndDate, setFilterEndDate] = useState(null)


  useEffect(() => {
    getPaymentHistory();
  }, [])

  useEffect(() => {
    filterTableByDate();
  }, [filterStartDate,filterEndDate])

  const getPaymentHistory = () => {
    Payment.all().then((response) => {
      if (response.response) {
          console.log(response);
          setFlash({type: "danger",  message: "Unable retrieve payment history."})
      } else {
        console.log(response);
        setPaymentHistory(response.data)
        setFilteredHistory(response.data)
      }
    });
  }

  const filterTableByDate = () => {
    if (filterStartDate && filterEndDate) {
      const startEpoch = filterStartDate.getTime();
      const endEpoch = filterEndDate.getTime();
      console.log(startEpoch)
      console.log(endEpoch)
      const searchResult = paymentHistory.filter((obj) => {
        console.log(obj)
        return startEpoch < obj.epoch_paid_at && endEpoch > obj.epoch_paid_at;
      });

      setFilteredHistory(searchResult);
    }
  }; 

  const dateFilter = () => (
    <div className="input-group row">
      <div>
      <div>
        <label htmlFor="start" className="date-label">Start Date</label>
        <DatePicker
          className="form-control"
          selected={filterStartDate}
          showTimeSelect
          dateFormat="MM/dd/yy h:mm aa"
          onChange={(date) => setFilterStartDate(date)}
        />
      </div>
      <div>
        <label htmlFor="end" className="date-label">End Date</label>
        <DatePicker
          className="form-control"
          selected={filterEndDate}
          showTimeSelect
          dateFormat="MM/dd/yy h:mm aa"
          onChange={(date) => {
            if (date < filterStartDate) {
              alert("END DATE MUST BE AFTER START DATE");
              return;
            }
            setFilterEndDate(date);
          }}
        />
      </div>
      </div>
      <p>Note: Run an empty search to clear all filters. The filter is progressive so it filters what is seen on the table.</p>
    </div>
  );

  const resetDateFilters = () => {
  //resets the date input boxes when user hits enter on search
    setFilterStartDate(null);
    setFilterEndDate(null);
  };


  const showTable = () => {
    // the key is the display you want on the table and the value is the key for the data returned from the api
    const columnSettings = {
      'Job': 'job_name',
      'Paid By': 'paid_by_display_name',
      'Amount': 'amount',
      'UUID': 'uuid',
      'Type': 'type',
      'Date': 'pretty_paid_at'
    }

    return <BsDataTable
      setFilteredData={setFilteredHistory} 
      columnSettings={columnSettings} 
      filteredData={filteredHistory} 
      originalData={paymentHistory}
      csvFilename="bluesmith-payments.csv"
      onReset={resetDateFilters}
    />
  }

  return <>
  <h1>Payment History</h1>
    {dateFilter()}
    {showTable()}
  </>
}