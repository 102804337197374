import { useState, useEffect } from 'react';
import { Machine } from '../models/machine';
import Select from 'react-select';

export default function MachineMaterialTable(props) {
    const [selectedPrinter, setSelectedPrinter] = useState(false);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [allPrinters, setAllPrinters] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);

    useEffect(() => {
        //if (props.selectedPrinter) {setSelectedPrinter(props.selectedPrinter);};
        if (props.selectedMaterials) {setSelectedMaterials(props.selectedMaterials);};
        Machine.all().then((response) => {
            if (response.response) {
                console.log(response);
            } else {
                const printerOptions = response.data.filter(m => m.availability || m.id == props.selectedPrinter);
                setAllPrinters(printerOptions);
                if (props.selectedPrinter) {
                    setSelectedPrinter(props.selectedPrinter);
                    const printer = response.data.find(p => p.id == props.selectedPrinter);
                    const materials = printer.materials.map(m => {return {value: m.id, label: m.name}})
                    setMaterialOptions(materials)
                }
            }
        });
    }, []);

    const handlePrinterChange = (e) => {
        const printer = allPrinters.find(p => p.id == e.target.value);
        setSelectedPrinter(e.target.value);
        const materials = printer.materials.map(m => {return {value: m.id, label: m.name}});
        setMaterialOptions(materials);
        setSelectedMaterials([]);
    };

    const handleMaterialChange = (e) => {
        setSelectedMaterials(e)
        if (e.length == 0) {
            setSelectedPrinter(false);
            setMaterialOptions([]);
            props.handleMachineMaterialUpdate(false, []);
        } else {
            props.handleMachineMaterialUpdate(selectedPrinter, e);
        }
    };

    return <div className="mb-5">
        <p><strong>Printer</strong></p>
        <select onChange={(e) => handlePrinterChange(e)} className="form-select" aria-label="printer" id="printer" value={selectedPrinter} defaultValue={false}>
            <option value={false} disabled>Select Printer</option>
            {allPrinters.map((p, index) => <option value={p.id} key={p.name + index}>{p.name}</option>)}
        </select>
        
        <p><strong>Materials</strong></p>
        <Select isMulti options={materialOptions} id="materials" onChange={(e) => handleMaterialChange(e)} value={selectedMaterials} />
    </div>
}